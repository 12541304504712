import { fetchPartnersList, fetchHomeClassList, fecthPCHomeList } from '@/api/board';
const board = {
  state: {
    homeData: {
      news: [],
      partners: [],
      dpClass: [],
      loaded: false,
    },
    homeClassList: [],
  },
  getters: {
    homeData: state => state.homeData,
    getDpClassWithDummy: state => {
      const NUM_OF_SLIDES = 3;
      let dpClass = state.homeData.dpClass.map(dpClass => {
        dpClass.type = 'class';
        return dpClass;
      });
      if (dpClass.length < 9) {
        dpClass = [...dpClass, { type: 'add', bid: 0 }];
      }
      const remainder = (NUM_OF_SLIDES - (dpClass.length % NUM_OF_SLIDES)) % NUM_OF_SLIDES;
      for (let i = 0; i < remainder; i++) {
        dpClass = [...dpClass, { type: 'dummy', bid: i }];
      }
      return dpClass;
    },
    homeClassList: state => state.homeClassList,
  },
  mutations: {
    homeData(state, payload) {
      state.homeData.news = payload.newsList;
      // state.homeData.dpClass = payload.docpleClassList;
      state.homeData.partners = payload.partnersList;
      state.homeData.loaded = true;
    },
    setHomeClassList(state, payload) {
      state.homeClassList = payload;
    },
  },
  actions: {
    async getHomeData({ commit, state }) {
      if (state.homeData.loaded) {
        return false;
      }
      try {
        /**
         * @author Ash
         * @date 2024.02.22
         * @description mainPC api 데이터 사용 안해서 주석처리 기간 지나면 코드 제거 필요, 연관된 state까지 삭제
         */
        const response = await Promise.all([
          fecthPCHomeList({
            communityTopCount: '0',
            newsTopCount: '12',
          }),
          fetchPartnersList({}),
        ]);
        let payload = {};
        response.forEach(({ data }) => {
          if (data.resultCode === '0') {
            payload = {
              ...payload,
              ...data.result,
            };
          }
        });
        commit('homeData', payload);
      } catch (e) {
        throw Error(e);
      }
    },
    async getHomeClassList({ commit }) {
      try {
        const { data } = await fetchHomeClassList();

        commit('setHomeClassList', data?.body);
        return data?.body || [];
      } catch (e) {
        commit('setHomeClassList', []);
        console.error(e);
        return [];
      }
    },
  },
};

export default board;
